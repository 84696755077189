/// Unfortunately the controls cannot extend a class and interfaces are not very useful here
/// Unfortunately the controls cannot extend a class and interfaces are not very useful here

//import {Components} from "../components";
import {ExternalRender} from "../components/external-render/external-render";
import {autoLogin} from "../components/login/global-login/loginUtils";
import AlloyFinger from 'alloyfinger';

//import {renderToString} from "@stencil/core/internal/hydrate/runner";
export let stopBubbling = (e) => e.stopPropagation();

export function curry(f) { // curry(f) does the currying transform
  return function (a) {
    return function (b) {
      return f(a, b);
    };
  };
}

export interface EliteController {

}

export function showContent(content) {
  //let elExternal: any = document.getElementById("contentTarget");
  let elExternal: any = document.getElementById("externalRender");

  if (content)
    (elExternal as ExternalRender).externalContent = content;
  //ReactDOM.render(myelement, document.getElementById('root'))
  //elExternal.innerHTML = renderToString(content)
}

/*export function setCookie(cookieName, cookieValue, expirationDays) {
  let date = new Date();

  date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));

  let expires = "expires=" + date.toUTCString();

  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');

  for (let c of ca) {
    while (c.charAt(0) == ' ')
      c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0)
      return c.substring(nameEQ.length, c.length);
  }

  return null;
}*/

export function getAccessJwtProvider(): JwtProducer {
  return AuthStore.getInstance().get("access")
}

export function getAccessJwtTokenAsync(onSuccess: (String) => void) {
  let provider: JwtProducer = getAccessJwtProvider()

  if (provider)
    provider.asyncGet("getAccessJwtTokenAsync()", jwtRole => {
      onSuccess(jwtRole)
    }, showError, true)
}

export function isLoggedIn(): boolean {
  return getAccessJwtProvider() != null;
}

export function euDateFromMillis(time: number, addTime: boolean): string {
  let date = new Date(time)
  let str = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();

  if (addTime) {
    str += " " + date.getHours() + ":" + date.getMinutes()
  }

  return str;
}

export function ignoreError(_err, _opt = null) {
}

export function ignoreSuccess(_text, _status = null) {
}

export function showSuccess(text) {
  alert(text)
}

export function showOnSuccess(blob) {
  let url = URL.createObjectURL(blob);
  window.open(url, "_blank")
}

export function downloadOnSuccess(blob, fileName: string) {
  let url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function extractError(err) {
  if (!err || (typeof err == "string" && !err.trim().startsWith("{")))
    return err;

  try {
    let obj = typeof err == "string" ? JSON.parse(err) : err

    if (obj.status == 403)
      return "Access forbidden. Please login.";
    else if (obj.userMessage)
      return obj.userMessage;
    else if (obj._embedded?.errors && obj._embedded?.errors[0]?.message) {
      var msg = obj._embedded?.errors && obj._embedded?.errors[0]?.message
      return msg.replace("Internal Server Error: ", "");
    } else if (obj.message)
      return obj.message;
    else if (obj.error)
      return obj.error;
    else
      return err;
  } catch (e) {
    return err
  }
}
export function showError(err, httpStatus = -1) {
  if (!err || err == "") {
    if (httpStatus == 403)
      alert("User unauthorized. Please login again")

    // Network error
    return
  }

  alert(extractError(err))
}

export function handleEnter(e, onEnter) {
  if (e.key !== 'Enter')
    return;

  onEnter()
}

export enum Language {
  ENGLISH, NORWEGIAN
}

export class EliteConfig {
  /// NOTE: If adding URLs, please fix the functions replacing them and removing the port, to support multiple environments
  public static blogUrl = "http://localhost:8093/blog"
  public static encryptUrl = "http://localhost:8095/encrypt"
  public static feedbackUrl = "http://localhost:8093/feedback"
  public static menuUrl = "http://localhost:8093/menu"
  public static urlGProfilePort = 'http://localhost:8091';
  public static urlGProfile = EliteConfig.urlGProfilePort + '/profile';
  public static urlGNewsChannels = EliteConfig.urlGProfilePort + '/newschannels';
  public static urlGLandingStats = EliteConfig.urlGProfilePort + '/landingstats';
  public static urlGGdprAnonymous = EliteConfig.urlGProfilePort + '/gdpr-anonymous';
  public static profileRoleUrl = "http://localhost:8092/role";
  public static profileRoleAdminUrl = "http://localhost:8092/roleAdmin";
  public static domainsUrl = "http://localhost:8092/domains";
  public static inboxUrl = "http://localhost:8901/inbox"
  public static contractsUrl = "http://localhost:8907/contracts"
  public static contractsAdminUrl = "http://localhost:8907/contractsAdmin"
  public static calendarUrl = "http://localhost:8907/calendars"
  public static logosDir = "http://localhost:8080/logos"
  public static jsonDir = "http://localhost:8080/social/json"
  //public static jsonDir = "http://localhost:8080/assets"
  public static squireDir = "http://localhost:8080/squire"
  public static statsUrl = "http://localhost:8902/stats";
  public static iconsUrl = "http://localhost:8080/emoji";
  public static photosUrl = "http://localhost:8094/photos";
  public static photosCommentsUrl = "http://localhost:8094/comments";
  public static photosStatsUrl = "http://localhost:8094/stats";
  public static mediaUrl = "http://localhost:8094/media";
  public static videoStreamUrl = "http://localhost:8921/video-stream";
  public static eventsUrl = "http://localhost:8920/events";
  public static eventsTicketsUrl = "http://localhost:8920/events-tickets";
  public static eventsTicketsPaymentsUrl = "http://localhost:8920/events-tickets/payments";
  public static mediaPaymentsUrl = "http://localhost:8094/media/payments";
  public static contactsUrl = "http://localhost:8910/contacts";
  public static commentsUrl = "http://localhost:8906/comments";
  public static financialNewsUrl = "http://localhost:8930/financialnews"
  public static realtimeWebSocket = "ws://localhost:8911/realtime/ws"
  public static cssUrl = "http://localhost:8080/css"
  public static imagesUrl = "http://localhost:8080/images"

  public static language = Language.ENGLISH

  /// NOTE: If adding URLs, please fix the functions changing them to support multiple environments
  public static readonly NETWORK_ID = 1
  public static readonly MEDIUM_RESOLUTION = 1024
  public static replacingUrl = null
  public static environmentName = "unknown";
  public static staticUrl: string = null;

  static replaceIfNeeded(url: string) {
    let localUrl = "http://localhost"

    return EliteConfig.replacingUrl && url ? url.replace(localUrl, EliteConfig.replacingUrl) : url
  }

  static removePort(url: string) {
    let idxColon = url.indexOf(":", 8)

    if (idxColon < 0)
      return url;

    let idxSlash = url.indexOf("/", idxColon + 1)

    if (idxSlash < 0)
      return url;

    let strPort = url.substring(idxColon, idxSlash)

    return url.replace(strPort, "")
  }

  static setupUrl(environmentName: string, baseUrl: string, removePorts, staticUrl: string) {
    EliteConfig.environmentName = environmentName

    //console.log("setupUrl(): " + environmentName +": " +  baseUrl + " - " + staticUrl)

    if (baseUrl)
      this.replaceWith(baseUrl, staticUrl)

    if (removePorts) {
      EliteConfig.blogUrl = EliteConfig.removePort(EliteConfig.blogUrl)
      EliteConfig.encryptUrl = EliteConfig.removePort(EliteConfig.encryptUrl)
      EliteConfig.feedbackUrl = EliteConfig.removePort(EliteConfig.feedbackUrl)
      EliteConfig.menuUrl = EliteConfig.removePort(EliteConfig.menuUrl)
      EliteConfig.urlGProfile = EliteConfig.removePort(EliteConfig.urlGProfile)
      EliteConfig.urlGNewsChannels = EliteConfig.removePort(EliteConfig.urlGNewsChannels)
      EliteConfig.urlGLandingStats = EliteConfig.removePort(EliteConfig.urlGLandingStats)
      EliteConfig.urlGGdprAnonymous = EliteConfig.removePort(EliteConfig.urlGGdprAnonymous)
      EliteConfig.profileRoleUrl = EliteConfig.removePort(EliteConfig.profileRoleUrl)
      EliteConfig.profileRoleAdminUrl = EliteConfig.removePort(EliteConfig.profileRoleAdminUrl)
      EliteConfig.domainsUrl = EliteConfig.removePort(EliteConfig.domainsUrl)
      EliteConfig.inboxUrl = EliteConfig.removePort(EliteConfig.inboxUrl)
      EliteConfig.contractsUrl = EliteConfig.removePort(EliteConfig.contractsUrl)
      EliteConfig.contractsAdminUrl = EliteConfig.removePort(EliteConfig.contractsAdminUrl)
      EliteConfig.calendarUrl = EliteConfig.removePort(EliteConfig.calendarUrl)
      EliteConfig.logosDir = EliteConfig.removePort(EliteConfig.logosDir)
      EliteConfig.jsonDir = EliteConfig.removePort(EliteConfig.jsonDir)
      EliteConfig.squireDir = EliteConfig.removePort(EliteConfig.squireDir)
      EliteConfig.statsUrl = EliteConfig.removePort(EliteConfig.statsUrl)
      EliteConfig.iconsUrl = EliteConfig.removePort(EliteConfig.iconsUrl)
      EliteConfig.photosUrl = EliteConfig.removePort(EliteConfig.photosUrl)
      EliteConfig.photosCommentsUrl = EliteConfig.removePort(EliteConfig.photosCommentsUrl)
      EliteConfig.photosStatsUrl = EliteConfig.removePort(EliteConfig.photosStatsUrl)
      EliteConfig.mediaUrl = EliteConfig.removePort(EliteConfig.mediaUrl)
      EliteConfig.mediaPaymentsUrl = EliteConfig.removePort(EliteConfig.mediaPaymentsUrl)
      EliteConfig.contactsUrl = EliteConfig.removePort(EliteConfig.contactsUrl)
      EliteConfig.commentsUrl = EliteConfig.removePort(EliteConfig.commentsUrl)
      EliteConfig.financialNewsUrl = EliteConfig.removePort(EliteConfig.financialNewsUrl)
      EliteConfig.realtimeWebSocket = EliteConfig.removePort(EliteConfig.realtimeWebSocket)
      EliteConfig.cssUrl = EliteConfig.removePort(EliteConfig.cssUrl)
      EliteConfig.imagesUrl = EliteConfig.removePort(EliteConfig.imagesUrl)
    }

    EliteConfig.staticUrl = staticUrl
  }

  static replaceWith(replacingUrl: string, staticUrl: string) {
    EliteConfig.replacingUrl = replacingUrl
    let localUrl = "http://localhost"

    EliteConfig.blogUrl = EliteConfig.blogUrl.replace(localUrl, replacingUrl)
    EliteConfig.encryptUrl = EliteConfig.encryptUrl.replace(localUrl, replacingUrl)
    EliteConfig.feedbackUrl = EliteConfig.feedbackUrl.replace(localUrl, replacingUrl)
    EliteConfig.menuUrl = EliteConfig.menuUrl.replace(localUrl, replacingUrl)
    EliteConfig.urlGProfile = EliteConfig.urlGProfile.replace(localUrl, replacingUrl)
    EliteConfig.urlGNewsChannels = EliteConfig.urlGNewsChannels.replace(localUrl, replacingUrl)
    EliteConfig.urlGLandingStats = EliteConfig.urlGLandingStats.replace(localUrl, replacingUrl)
    EliteConfig.urlGGdprAnonymous = EliteConfig.urlGGdprAnonymous.replace(localUrl, replacingUrl)
    EliteConfig.profileRoleUrl = EliteConfig.profileRoleUrl.replace(localUrl, replacingUrl)
    EliteConfig.profileRoleAdminUrl = EliteConfig.profileRoleAdminUrl.replace(localUrl, replacingUrl)
    EliteConfig.domainsUrl = EliteConfig.domainsUrl.replace(localUrl, replacingUrl)
    EliteConfig.inboxUrl = EliteConfig.inboxUrl.replace(localUrl, replacingUrl)
    EliteConfig.contractsUrl = EliteConfig.contractsUrl.replace(localUrl, replacingUrl)
    EliteConfig.contractsAdminUrl = EliteConfig.contractsAdminUrl.replace(localUrl, replacingUrl)
    EliteConfig.calendarUrl = EliteConfig.calendarUrl.replace(localUrl, replacingUrl)
    EliteConfig.statsUrl = EliteConfig.statsUrl.replace(localUrl, replacingUrl)
    EliteConfig.photosUrl = EliteConfig.photosUrl.replace(localUrl, replacingUrl)
    EliteConfig.photosCommentsUrl = EliteConfig.photosCommentsUrl.replace(localUrl, replacingUrl)
    EliteConfig.photosStatsUrl = EliteConfig.photosStatsUrl.replace(localUrl, replacingUrl)
    EliteConfig.mediaUrl = EliteConfig.mediaUrl.replace(localUrl, replacingUrl)
    EliteConfig.mediaPaymentsUrl = EliteConfig.mediaPaymentsUrl.replace(localUrl, replacingUrl)
    EliteConfig.contactsUrl = EliteConfig.contactsUrl.replace(localUrl, replacingUrl)
    EliteConfig.commentsUrl = EliteConfig.commentsUrl.replace(localUrl, replacingUrl)
    EliteConfig.financialNewsUrl = EliteConfig.financialNewsUrl.replace(localUrl, replacingUrl)
    EliteConfig.realtimeWebSocket = EliteConfig.realtimeWebSocket.replace(localUrl, replacingUrl)

    if (staticUrl) {
      EliteConfig.logosDir = EliteConfig.logosDir.replace(localUrl, staticUrl)
      EliteConfig.jsonDir = EliteConfig.jsonDir.replace(localUrl, staticUrl)
      EliteConfig.squireDir = EliteConfig.squireDir.replace(localUrl, staticUrl)
      EliteConfig.iconsUrl = EliteConfig.iconsUrl.replace(localUrl, staticUrl)
      EliteConfig.cssUrl = EliteConfig.cssUrl.replace(localUrl, staticUrl)
      EliteConfig.imagesUrl = EliteConfig.imagesUrl.replace(localUrl, staticUrl)
    }
  }
}

export class AutoLog {
  time: number
  message: string

  constructor(message: string) {
    this.time = Date.now();
    this.message = message;
  }
}

// @ts-ignore
export class AuthStore {
  private static instance: AuthStore = new AuthStore();
  private dataStore: Map<string, JwtProducer> = new Map();
  private licenseParams: any
  private loginCallbacks: Map<string, ((event: JwtEvent, jwt: JwtProducer) => void /* false to remove the listener */ )> = new Map()
  email: string
  public log: AutoLog[] = []

  private constructor() {
  }

  public static getInstance(): AuthStore {
    return AuthStore.instance;
  }

  public static log(message: string) {
    AuthStore.getInstance().log.push(new AutoLog(message))
}


  // Should we support more than email?
  public setJwtAccess(email: string, producer: JwtProducer, profile: any = null): void {
    this.dataStore.set("access", producer)
    this.email = email

    let parts = producer.extract()

    //console.log("Part 0: " + parts[0])
    DataStorages.auto().setItem("jwtAccessToken/" + email, parts[0]);
    DataStorages.auto().setItem("jwtRefreshToken/" + email, parts[1]);
    DataStorages.auto().setItem("jwtRefreshUrl/" + email, parts[2]);
    DataStorages.session().setItem("jwtAccessTokenSession", parts[0]);
    DataStorages.session().setItem("jwtRefreshTokenSession", parts[1]);
    DataStorages.session().setItem("jwtRefreshUrlSession", parts[2]);

    if (profile) {
      let jsonProfile = JSON.stringify(profile)
      DataStorages.auto().setItem("profile/" + email, jsonProfile);
      DataStorages.session().setItem("profileSession", jsonProfile);
    }

    this.loginCallbacks.forEach((callback, _name ) => callback(JwtEvent.LOGIN, producer))
  }

  static jwtFromLocalStorage(email: string): JwtProducer {
    Debug.trace("jwtFromLocalStorage()")
    let jwtAccessToken = DataStorages.auto().getItem("jwtAccessToken/" + email);
    let jwtRefreshToken = DataStorages.auto().getItem("jwtRefreshToken/" + email);
    let jwtRefreshUrl = DataStorages.auto().getItem("jwtRefreshUrl/" + email);
    let profile = DataStorages.auto().getItem("profile/" + email);

    Debug.trace("token retrieved")
    jwtRefreshUrl = EliteConfig.replaceIfNeeded(jwtRefreshUrl)

    if (jwtAccessToken && jwtRefreshToken && jwtRefreshUrl) {
      Debug.trace("creating producer")
      return new JwtProducer(jwtAccessToken, jwtRefreshToken, jwtRefreshUrl, profile, "jwtFromLocalStorage")
    }

    Debug.trace("no tokens in local storage: AT-" + (jwtAccessToken != null) + " RT-" + (jwtRefreshToken != null) + " RU-" + (jwtRefreshUrl != null) + " ")
    return null
  }

  static jwtFromSessionStorage(): JwtProducer {
    Debug.trace("jwtFromSessionStorage()")
    let jwtAccessToken = DataStorages.session().getItem("jwtAccessTokenSession");
    let jwtRefreshToken = DataStorages.session().getItem("jwtRefreshTokenSession");
    let jwtRefreshUrl = DataStorages.session().getItem("jwtRefreshUrlSession");
    let profile = DataStorages.session().getItem("profileSession");

    Debug.trace("token retrieved")
    jwtRefreshUrl = EliteConfig.replaceIfNeeded(jwtRefreshUrl)

    if (jwtAccessToken && jwtRefreshToken && jwtRefreshUrl) {
      Debug.trace("creating producer")
      return new JwtProducer(jwtAccessToken, jwtRefreshToken, jwtRefreshUrl, profile, "jwtFromSessionStorage")
    }

    Debug.trace("no tokens in session storage: AT-" + (jwtAccessToken != null) + " RT-" + (jwtRefreshToken != null) + " TU-" + (jwtRefreshUrl != null) + " ")
    return null
  }

  static profileFromLocalStorage(email: string): string {
    // localStorage
    return DataStorages.auto().getItem("profile/" + email);
  }

  public get(type: string): JwtProducer {
    return this.dataStore.get(type);
  }

  public setLicenseParams(params: any) {
    this.licenseParams = params
  }

  public getLicenseParams() {
    return this.licenseParams
  }

  private extractNumberFromParams(attributeName: string) {
    let str = attributeName + "\":"
    let idx = this.licenseParams.indexOf(attributeName)

    if (idx < 0)
      return null

    idx += str.length
    let idx2 = idx;

    while (this.licenseParams.charAt(idx2) != ',' && this.licenseParams.charAt(idx2) != '}')
      idx2++

    return this.licenseParams.substring(idx, idx2)
  }

  public extractQuotas() {
    return {numMB: this.extractNumberFromParams("numMB"), numPhotos: this.extractNumberFromParams("numPhotos")}
  }

  public refreshJwtAccessOnly(jwtAccessToken: any) {
    let jwt = getAccessJwtProvider()

    AuthStore.log("refreshJwtAccessOnly: jwt is " + (jwt ? "not null" : "null"))

    if (jwt) {
      this.setJwtAccess(this.email, jwt.withAccessToken(jwtAccessToken))
    }
  }

  public logOut() {
    this.dataStore.set("access", null)

    DataStorages.removeItemsWithPrefix("jwt")
    DataStorages.removeItemsWithPrefix("profile/")
    DataStorages.removeItemsWithPrefix("profileSession")
    DataStorages.removeItemsWithPrefix("userJustSignedUp")
    DataStorages.inMemory().clear()

    // FIXME: circular dependency; there should be a list of items to preserve on logout
    /*let rememberMeEmail = DataStorages.auto().getItem('login-email')
    let paymentProviderId = sessionStorage.getItem(ShoppingCartContentDto.PROVIDER_ID_SESSION_STORAGE_NAME)
    let shoppingCart = sessionStorage.getItem("shopping-cart")
    DataStorages.auto().clear()
    DataStorages.session().clear()
    DataStorages.auto().setItem('login-email', rememberMeEmail)
    UsersProfileStore.getInstance().clearMyLastProfileRole()

    if (paymentProviderId)
      sessionStorage.setItem(ShoppingCartContentDto.PROVIDER_ID_SESSION_STORAGE_NAME, paymentProviderId)
    if (shoppingCart)
      sessionStorage.setItem("shopping-cart", shoppingCart)*/

    this.loginCallbacks.forEach((callback, _name ) => callback(JwtEvent.LOGOUT, null))
  }

  public getRememberMeEmail(): string {
    return DataStorages.auto().getItem('login-email')
  }

  public setRememberMeEmail(email: string): void {
    DataStorages.auto().setItem('login-email', email)
  }

  public addLoginCallback(name: string, callback: (event: JwtEvent, jwt: JwtProducer) => void): void {
    this.loginCallbacks.set(name, callback)
  }

  public cleanCallbacks() {
    this.loginCallbacks.clear()
  }
}

class CacheEntry<V> {
  data: V
  expiration: number
  callbacks: Array<any>;

  constructor(data: V, expiration: number) {
    this.data = data;
    this.expiration = expiration;
    this.callbacks = new Array<any>();
  }
}

export function insertAfter(existingNode, newNode) {
  existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}

export function insertBefore(existingNode, newNode) {
  existingNode.parentNode.insertBefore(newNode, existingNode);
}

export function removeAllChildNodes(parent, exceptChild) {
  let removed = false;

  while (parent && parent.firstChild) {
    if (parent.firstChild == exceptChild)
      removed = true;
    parent.removeChild(parent.firstChild);
  }

  if (removed)
    parent.appendChild(exceptChild)
}

export function htmlToElement(html) {
  let template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content.firstChild;
}

class Cache<V> {
  private loadFunction: (key, onSuccess: (value) => void, onError: (error) => void) => void;
  private lifeMs: number;
  // FIXME: limit the number of entries stored
  private cache: { [key: string]: CacheEntry<V>; } = {}
  private name

  constructor(loadFunction: (key, onSuccess: (value) => void, onError: (error) => void) => void, lifeMs: number, name: String) {
    this.loadFunction = loadFunction;
    this.lifeMs = lifeMs;
    this.name = name
  }

  public getName() {
    return this.name
  }

  public put(key: any, result: any, notifyCallbacks: boolean = true, info: string = null) {
    if (this.cache[key]) {
      this.cache[key].data = result;
      this.cache[key].expiration = new Date().getTime() + this.lifeMs;
      info += ", cache present"
    } else {
      this.cache[key] = new CacheEntry(result, new Date().getTime() + this.lifeMs)
    }
    info += ", no cache"

    if (notifyCallbacks)
      this.notifyCallbacks(key, result, info)
  }

  private notifyCallbacks(key: any, result: any, _info: string = null) {
    let cachedValue: CacheEntry<any> = this.cache[key]
    let callbacks = cachedValue.callbacks ? [...cachedValue.callbacks] : cachedValue.callbacks

    if (callbacks) {
      cachedValue.callbacks.length = 0;
      //console.log("Notify " + callbacks.length + " of " + key + ": " + JSON.stringify(result) + " - info: " + info)

      for (let i = 0; i < callbacks.length; i++) {
        if (callbacks[i])
          callbacks[i](result)
      }
    } //else
      //console.log("No callbacks to notify " + " of " + key + ": " + JSON.stringify(result) + " - info: " + info)
  }

  public getAsync(key: any, callback: (result) => void = null, onError: (error, status) => void = null): void {
    let cachedValue: CacheEntry<any> = this.cache[key]
    let value = null
    let info = "Initial caches value: " + (cachedValue ? JSON.stringify(cachedValue.data) : null)

    // if cachedValue.data is null, it means there is a loading in place
    if (cachedValue && cachedValue.data && cachedValue.expiration >= new Date().getTime()) {
      value = cachedValue.data
      info += ", not expired and not null"
    }

    if (value) {
      //console.log("getAsync() Calling callback immediately: " + info)
      callback(value)
    } else {
      if (!cachedValue) {
        cachedValue = this.cache[key] = new CacheEntry<any>(null, 0)
        info += ", cached Value null"
      }

      let newLength = cachedValue.callbacks.push(callback);

      //alert("GetAsync: " + key + " for " + this.name + " new Length: " + newLength)

      if (newLength == 1) // The value should be loaded only once
        this.loadFunction(key, result => {
          //console.log("loadFunction() value on " + key + ": " + JSON.stringify(result))
          this.put(key, result, true, info + ", loadFunction() value")
        }, err => {
          //console.log("loadFunction() error on " + key + ": " + err)
          this.put(key, null, true, info + ", loadFunction() error")
          if (onError)
            onError(err, -1)
        })
    }
  }

  public getUnsafe(key: any): any {
    let cachedValue: CacheEntry<any> = this.cache[key]

    return cachedValue ? cachedValue.data : null
  }
}

class CacheOneValue<V> {
  private cache: Cache<V>;
  private KEY = "_DUMMY_";

  constructor(loadFunction: (onSuccess: (V) => void, onError: (error, status) => void) => void, lifeMs: number, name: String) {
    this.cache = new Cache<V>((_key, onSuccess: (V) => void, onError: (error, status) => void) => loadFunction(onSuccess, onError), lifeMs, name)
  }

  public put(result: any, notifyCallbacks: boolean = true, info: string = null) {
    this.cache.put(this.KEY, result, notifyCallbacks, info ? info + ", CacheOneValue.put()" : "CacheOneValue.put()")
  }

  public getAsync(callback: (result) => void = null, onError: (error, status) => void = null): void {
    this.cache.getAsync(this.KEY, callback, onError)
  }

  public getUnsafe(): any {
    return this.cache.getUnsafe(this.KEY)
  }
}

export class RealTimeManager {
  private static instance: RealTimeManager = new RealTimeManager();
  private static realtimeWebSocketUrl = EliteConfig.realtimeWebSocket + "/"
  private webSocket: WebSocket
  public active = true
  private lastJwt: string
  private newJwt: string

  constructor() {
  }

  public static getInstance(): RealTimeManager {
    return RealTimeManager.instance;
  }

  private createWebSocket(jwt: string) {
    let that = this

    if (this.active && !this.webSocket && jwt && jwt != that.lastJwt) {
      this.webSocket = new WebSocket(RealTimeManager.realtimeWebSocketUrl + jwt);
      this.webSocket.onmessage = function (msg) {
        let obj = JSON.parse(msg.data)
        //alert(msg.type + " - " + msg.data + " - " + obj.cnt);
        new BroadcastChannel(obj.cnt).postMessage(obj)
      };
      this.webSocket.onclose = function () {
        console.log("WebSocket closed!")
        that.webSocket = null
        that.refreshWebSocket();
      }

      this.webSocket.onopen = function () {
        console.log("WebSocket opened!")
        that.lastJwt = jwt
        //this.send("state?/103")
      }
    }
  }

  private refreshWebSocket() {
    let jwtToUse = this.newJwt ? this.newJwt : this.lastJwt
    let jwtExtracted = extractJwtUnsafe(jwtToUse)

    if (!jwtExtracted)
      return

    let expiring = jwtExtracted.exp < (Date.now() / 1000 + 3)

    if (expiring) { // No one refreshed the JWT
      //console.log("refreshWebSocket() - JWT expired - autoLogin()")
      autoLogin((jwtAccess, _publicRole) => {
        //console.log("refreshWebSocket() - got JWT from autoLogin()")
        RealTimeManager.getInstance().notifyOnline(jwtAccess);
      }, () => ignoreError)
    } else {
      //console.log("refreshWebSocket() - JWT expired - autoLogin()")
      this.lastJwt = null
      this.createWebSocket(jwtToUse)
    }
  }

  public notifyOnline(jwtToken: string) {
    if (this.active)
      this.createWebSocket(jwtToken)
  }

  public setNewJwt(jwtAccess: string) {
    this.newJwt = jwtAccess
  }
}

export function loadMyLastProfileRole(onSuccess: (role) => void, onError: (err) => void = null, byPassCache = false) {
  UsersProfileStore.getInstance().loadMyLastProfileRole(onSuccess, onError, byPassCache)
}

export function loadMyLastPublicProfile(onSuccess: (publicRole) => void) {
  UsersProfileStore.getInstance().loadMyLastPublicProfile(onSuccess)
}

export function loadMyLastRoleId(onSuccess: (publicRole) => void) {
  UsersProfileStore.getInstance().loadMyLastRoleId(onSuccess)
}

export function geoLocate(onSuccess: (geoLocation) => void) {
  UsersProfileStore.getInstance().getGeoLocate(onSuccess)
}

export class UsersProfileStore {
  private static instance: UsersProfileStore = new UsersProfileStore();
  private cacheRoles: Cache<any>;
  private cacheRolesAlbumConfig: Cache<any>;
  private cacheTheme: Cache<any>;
  private cacheMyLastRole: CacheOneValue<any>;
  private cacheThemeOptions: CacheOneValue<any>;
  private cachePresets: CacheOneValue<any>;
  private cacheGeoIp: CacheOneValue<any>;
  private mapNames: Map<number, string> = new Map() // role => full name

  constructor() {
    this.cacheRoles = new Cache<any>((owner, onSuccess: (result: any) => void) => {
      getHttp(EliteConfig.profileRoleUrl + "/public/role/" + owner, (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, showError, "UsersProfileStore:cacheRoles")
    }, 300000, "cacheRoles")

    this.cacheRolesAlbumConfig = new Cache<any>((owner, onSuccess: (result: any) => void) => {
      getHttp(EliteConfig.profileRoleUrl + "/public/role/" + owner + "/albumsConfig", (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, showError, "UsersProfileStore:cacheRolesConfig")
    }, 300000, "cacheRolesAlbumConfig")

    this.cacheTheme = new Cache<any>((owner, onSuccess: (result: any) => void, onError: (result: any) => void) => {
      getHttp(EliteConfig.profileRoleUrl + "/public/role/" + owner + "/theme", (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, (text, _status) => {
        onError(text)
      }, "UsersProfileStore:cacheTheme")
    }, 86400000, "cacheTheme")

    this.cacheMyLastRole = new CacheOneValue<any>((onSuccess: (result: any) => void, onError: (error, status) => void) => {
      getHttp(EliteConfig.profileRoleUrl + "/lastRole", (text, _status) => {
        let roleResult = JSON.parse(text).result

        //console.log("Last role: " + JSON.stringify(roleResult))
        onSuccess(roleResult)
      }, onError == null ? showError : onError, "UsersProfileStore:cacheMyLastRole")
    }, 300000, "cacheMyLastRole")

    this.cacheGeoIp = new CacheOneValue<any>((onSuccess: (result: any) => void) => {
      getHttp(EliteConfig.profileRoleUrl + "/public/geo", (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, showError, "UsersProfileStore:cacheGeoIp")
    }, 300000, "cacheGeoIp")

    this.cachePresets = new CacheOneValue<any>((onSuccess: (result: any) => void) => {
      getHttp(EliteConfig.profileRoleUrl + "/public/presets", (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, showError, "UsersProfileStore:cachePresets")
    }, 300000, "cachePresets")

    this.cacheThemeOptions = new CacheOneValue<any>((onSuccess: (result: any) => void) => {
      getHttp(EliteConfig.profileRoleUrl + "/public/themesOptions", (text, _status) => {
        onSuccess(JSON.parse(text).resultJson)
      }, showError)
      getHttp(EliteConfig.profileRoleUrl + "/lastRole", (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, showError, "UsersProfileStore:cacheThemeOptions")
    }, 300000, "cacheThemeOptions")
  }

  public static getInstance(): UsersProfileStore {
    return UsersProfileStore.instance;
  }

  public loadPublicProfileRole(roleId, onSuccess: (role) => void) {
    this.cacheRoles.getAsync(roleId, onSuccess)
  }

  public loadPublicAlbumsConfig(owner, onSuccess: (role) => void) {
    this.cacheRolesAlbumConfig.getAsync(owner, onSuccess)
  }

  public loadMyLastProfileRole(onSuccess: (role) => void, onError: (error, status) => void = null, byPassCache = false) {
    if (byPassCache)
      this.cacheMyLastRole.put(null, false, "loadMyLastProfileRole()-byPassCache")

    this.cacheMyLastRole.getAsync(onSuccess, onError)
  }

  // Uses a custom JWT Provider and Cache bypass
  public loadMyLastProfileRoleJwt(jwtProvider: JwtProducer, onSuccess: (role) => void, onError: (error, status) => void = null) {
    getHttp(EliteConfig.profileRoleUrl + "/lastRole", (text, _status) => {
      let roleResult = JSON.parse(text).result

      this.cacheMyLastRole.put(roleResult, true, "loadMyLastProfileRole()-byPassCache")
      onSuccess(roleResult)
    }, onError == null ? showError : onError, "UsersProfileStore:cacheMyLastRole", jwtProvider)
  }

  public clearMyLastProfileRole() {
      this.cacheMyLastRole.put(null, false, "clearMyLastProfileRole()")
  }

  public loadMyLastPublicProfile(onSuccess: (publicRole) => void) {
    this.cacheMyLastRole.getAsync(localProfileRole => onSuccess(localProfileRole.publicRole))
  }

  public loadMyLastRoleId(onSuccess: (roleId) => void) {
    this.cacheMyLastRole.getAsync(localProfileRole => {
      if (localProfileRole?.publicRole?.roleId)
        onSuccess(localProfileRole.publicRole.roleId)
    })
  }

  public getGeoLocate(onSuccess: (geoLocation) => void) {
    this.cacheGeoIp.getAsync(geoLocation => onSuccess(geoLocation))
  }

  public ifAdminOf(owner, onAdmin: () => void) {
    loadMyLastRoleId(roleId => {
      if (roleId == owner)
        onAdmin()
    })
  }

  public saveRole(role, onSuccess: () => void) {
    putHttp(EliteConfig.profileRoleUrl + "/role", onSuccess, showError, role)
  }

  public loadTheme(owner, onSuccess: (result: any) => void) {
    this.cacheTheme.getAsync(owner, t => {
      onSuccess(t)
    })
  }

  public loadThemeOptions(onSuccess: (themeOptions) => void) {
    this.cacheThemeOptions.getAsync(onSuccess)
  }

  public loadPresets(onSuccess: (preset) => void) {
    this.cachePresets.getAsync(onSuccess)
  }

  public adminLoadNamesFromRoles(roles: number[], onSuccess: (names: Map<number, string>) => void) {
    getHttpJson(EliteConfig.profileRoleAdminUrl + "/names/" + encodeURIComponent(roles.join(",")), names => {
      names = new Map(Object.entries(names.result))

      for (let name of names.keys()) {
        let roleId = parseInt(name, 10)
        this.mapNames.set(roleId, names.get(name))
        names.set(roleId, names.get(name))
      }

      onSuccess(names)
    }, showError)
  }

  public getMyProfileRoleUnsafe() {
    return this.cacheMyLastRole.getUnsafe()
  }

  public getMyRoleIdUnsafe() {
    let localProfileRole = this.cacheMyLastRole.getUnsafe()

    return localProfileRole ? localProfileRole.publicRole.roleId : -1;
  }
}

export class ContactsStore {
  private static instance: ContactsStore = new ContactsStore();
  private cacheContactsLight: CacheOneValue<any>;
  private cacheContactsFull: CacheOneValue<any>;

  constructor() {
    this.cacheContactsLight = new CacheOneValue<any>((onSuccess: (result: any) => void) => {
      getHttp(EliteConfig.contactsUrl + "/contacts/light", (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, showError, "ContactsStore:cacheContactsLight")
    }, 300000, "cacheContactsLight")

    this.cacheContactsFull = new CacheOneValue<any>((onSuccess: (result: any) => void) => {
      getHttp(EliteConfig.contactsUrl + "/contacts/full", (text, _status) => {
        onSuccess(JSON.parse(text).result)
      }, showError, "ContactsStore:cacheContactsFull")
    }, 300000, "cacheContactsFull")
  }

  public static getInstance(): ContactsStore {
    return ContactsStore.instance;
  }

  public loadContactsLight(onSuccess: (contacts) => void) {
    this.cacheContactsLight.getAsync(onSuccess)
  }

  public loadContactsFull(onSuccess: (contacts) => void) {
    this.cacheContactsFull.getAsync(onSuccess)
  }
}

export enum JwtEvent {
  LOGIN, LOGOUT
}

export class JwtProducer {
  private readonly accessToken: string;
  // @ts-ignore
  private readonly accessTokenExtracted: any;
  // @ts-ignore
  private readonly refreshToken: string;
  // @ts-ignore
  private readonly refreshUrl: string
  // @ts-ignore
  private readonly profile: string
  private tokenRenewalInProgress: Promise<any> | null = null;

  public constructor(jwtAccessToken: string, jwtRefreshToken: string, jwtRefreshUrl: string, profile: string, _reason: string) {
    this.accessToken = jwtAccessToken
    this.accessTokenExtracted = jwtAccessToken ? extractJwtUnsafe(jwtAccessToken) : null
    this.refreshToken = removeBearerFrom(jwtRefreshToken)
    this.refreshUrl = jwtRefreshUrl
    this.profile = profile

    //console.log("accessToken: " + this.accessToken)
    //console.log("accessTokenExtracted: " + JSON.stringify(this.accessTokenExtracted))
  }

  public getRefreshToken() {
    return this.refreshToken
  }

  public getAccessToken() {
    return this.accessToken
  }

  public getProfile(): string {
    return this.profile;
  }

  public extract() {
    //alert("Serialize url: " + this.refreshUrl)
    return [this.accessToken, this.refreshToken, this.refreshUrl]
  }

  public getRefreshUrl() {
    let urlGProfile = stripFinalUrlPart(EliteConfig.urlGProfile);

    return urlGProfile + this.refreshUrl
  }

  /*public serialize(): string {
    //alert("Serialize url: " + this.refreshUrl)
    return this.accessToken + "|" + this.refreshToken + "|" + this.refreshUrl
  }*/

  /*public static deserialize(str: string): JwtProducer {
    let parts = str.split("|")

    if (parts.length != 3)
      return null

    //alert("DeSerialize url: " + parts[2])
    return new JwtProducer(parts[0], parts[1], parts[2])
  }*/

  public withAccessToken(jwtAccessToken: string): JwtProducer {
    return new JwtProducer(jwtAccessToken, this.refreshToken, this.refreshUrl, this.profile, "withAccessToken");
  }

  public get(): string {
    return this.accessToken;
  }

  public asyncGet(reason: string, onSuccess: (jwt: string, reason: string) => void, onError: (error, status) => void, retrieveRole = true): void {
    let now = new Date().getTime();
    let secAccessLeft = this.accessTokenExtracted.exp - now / 1000
    let secAccessDuration = this.accessTokenExtracted.exp - this.accessTokenExtracted.issuedMs / 1000

    //console.assert(secAccessDuration > 0, "accessTokenExtracted: " + this.accessTokenExtracted.exp + " - issuedAt: " + (this.accessTokenExtracted.issuedMs / 1000))
    console.assert(secAccessDuration > 0, JSON.stringify(this.accessTokenExtracted))
    AuthStore.log("asyncGet() - secAccessLeft: " + secAccessLeft + " - secAccessDuration:" + secAccessDuration)
    console.log(new Date() + " - asyncGet() - secAccessLeft: " + secAccessLeft + " - secAccessDuration:" + secAccessDuration)

    if (secAccessLeft < secAccessDuration / 2) {
      let jwt: Promise<any> = this.renewAccessToken("asyncGet() - " + reason + " retrieve role: " + retrieveRole)

      jwt.then(globalProfile => {
          {
            //console.log("JWT before renewal: " + JSON.stringify(this.accessToken))
            //console.log("JWT after renewal: " + JSON.stringify(result))

            // If we retrieve the role, this will be overridden
            AuthStore.getInstance().setJwtAccess(AuthStore.getInstance().email, new JwtProducer(globalProfile.jwtAccess, globalProfile.jwtRefresh, globalProfile.jwtRefreshUrl, null, "asyncGet"));

            if (retrieveRole) {
              UsersProfileStore.getInstance().loadMyLastProfileRole(localProfileRole => {
                //let jwtProvider = new JwtProducer(globalProfile.jwtAccess, globalProfile.jwtRefresh, globalProfile.jwtRefreshUrl, globalProfile.profile, "asyncGet")
                //console.log("JWT asyncGet() received role: " + JSON.stringify(role))

                if (localProfileRole) {
                  AuthStore.getInstance().refreshJwtAccessOnly(localProfileRole.jwtRole)
                  AuthStore.getInstance().setLicenseParams(localProfileRole.effectiveParams)
                }

                let jwtAccess = localProfileRole?.jwtRole ? localProfileRole?.jwtRole : globalProfile.jwtAccess
                //AuthStore.getInstance().setJwtAccess(AuthStore.getInstance().email, new JwtProducer(jwtAccess, globalProfile.jwtRefresh, globalProfile.jwtRefreshUrl, globalProfile.profile, "asyncGet"))

                onSuccess(jwtAccess, localProfileRole?.jwtRole? "JWT Role" : "JWT Role null, using JWT Access")
              }, onError, true)
            } else
              onSuccess(globalProfile.jwtAccess, "JWT Access")
          }
        }, err => {
          if (onError)
            onError(err.userMessage, err.status)
        }
      )
    } else {
      return onSuccess(this.accessToken, "NotExpired");
    }
  }

  // TODO: is it ok that it is not used?
  /*public checkExpiration() {
    let now = new Date().getTime();
    let secAccessLeft = this.accessTokenExtracted.exp - now / 1000
    let secAccessDuration = this.accessTokenExtracted.exp - this.accessTokenExtracted.issuedMs / 1000

    console.assert(secAccessDuration > 0)

    if (secAccessLeft < secAccessDuration / 2) {
      let jwt: Promise<any> = this.renewAccessToken("checkExpiration()")

      jwt.then(text => alert("JWT after renewal: " + text))

      return true
    }

    return true;
  }*/

  private renewAccessToken(reason): Promise<any> {
    let now = new Date().getTime();
    let refreshTokenExtracted = extractJwtUnsafe(this.refreshToken)
    let secRefreshLeft = refreshTokenExtracted.exp - now / 1000

    if (secRefreshLeft <= 0 ) {
      AuthStore.getInstance().logOut()
      alert("Please login again, your refresh token is expired.")
      return Promise.reject("Please login again, your refresh token is expired.")
    }

    // FIXME: retrieve, somehow
    AuthStore.log("renewAccessToken(): " + reason)
    let urlGProfile = stripFinalUrlPart(EliteConfig.urlGProfile);
    // e.g. /profile/renew/1/ACCESS
    let urlString = urlGProfile + this.refreshUrl + "/" + EliteConfig.NETWORK_ID + "/ACCESS"

    try {
      let url = new URL(urlString)
      console.log("Fetch renewAccessToken(): " + reason)
      //alert("Renew Access token - JWT Provider: " + JSON.stringify(this))

      return this.fetchWithMutex(url);
    } catch (e) {
      console.log("renewAccessToken() - error: " + e)
      return Promise.reject("Error " + e)
    }
  }

  // Use a mutex or lock to avoid parallel requests
  private fetchWithMutex(url: URL) {
    if (this.tokenRenewalInProgress) {
      return this.tokenRenewalInProgress;
    }

    // Store the renewal process to prevent duplicate calls
    this.tokenRenewalInProgress = fetchAndReturnCors(url, true, "GET", this.refreshToken, this.accessToken)
      .then(res => {
        this.tokenRenewalInProgress = null; // Clear the flag once done
        return res.result;
      })
      .catch(err => {
        this.tokenRenewalInProgress = null; // Clear the flag in case of an error
        throw err;
      });

    return this.tokenRenewalInProgress;
  }
}

function stripFinalUrlPart(url: string): string {
  let idx = url.lastIndexOf('/')

  return url.substring(0, idx)
}


export function format(first: string, middle: string, last: string): string {
  return (
    (first || '') +
    (middle ? ` ${middle}` : '') +
    (last ? ` ${last}` : '')
  );
}

export function downloadHttp(url: string, onSuccess: (blob, status) => void, onError: (text, status) => void, body: string = null, traceId = null) {
  return http('GET', url, body, onSuccess, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, getAccessJwtProvider(), traceId, true)
}


export function downloadHttpAndOpenOnNewWindow(url: string, traceId = null) {
  downloadHttp(url, (blob, _status) => {
    let _url = URL.createObjectURL(blob);
    window.open(_url, "_blank")
  }, showError, traceId)
}


export function getHttp(url: string, onSuccess: (text, status) => void, onError: (text, status) => void, traceId: string = null, customJwtProvider: JwtProducer = null) {
  return http('GET', url, null, onSuccess, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, customJwtProvider ? customJwtProvider : getAccessJwtProvider(), traceId)
}

export function getHttpJson(url: string, onSuccess: (obj, status) => void, onError: (text, status) => void, traceId = null) {
  return http('GET', url, null,
    (text, status) => {
      if (onSuccess) onSuccess(JSON.parse(text), status)
    }, onError ? onError : function (text, status) {
      alert("Error: " + status + " - " + text)
    }, getAccessJwtProvider(), traceId)
}

export function getHttpJsonChunked(url: string, onSuccess: (obj, status) => void, onError: (text, status) => void) {
  fetchAndReturnCorsChunked(url, "GET",  (obj, status) => {
    try {
      let text = JSON.parse(obj)
      if (onSuccess)
        onSuccess(text, status)
    } catch(e) {
      alert(obj)
      console.log("Exception on getHttpJsonChunked() - a: " + e + "\n reason: " + obj.substring(0, 10) + "  ==> " + obj.substring(obj.length - 10))
      console.log("Exception on getHttpJsonChunked() - b: " + obj.length + ": " + obj.charAt(obj.length-2) + " - "+ obj.charAt(obj.length-1))
    }
  } , (text, status) => {
    console.log("Network error on : getHttpJsonChunked(): " + text + " - " + status)
    if (onError)
      onError(text, status)
  }, getAccessJwtProvider())
}

export function getHttpChunked(url: string, onSuccess: (obj, status) => void, onError: (text, status) => void) {
  fetchAndReturnCorsChunked2(url, "GET",  onSuccess, onError, getAccessJwtProvider())
}

export function getHttpBody(url: string, onSuccess: (text, status) => void, onError: (text, status) => void, body = null, traceId = null) {
  return http('GET', url, body, onSuccess, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, getAccessJwtProvider(), traceId)
}

export function getHttpBodyJson(url: string, onSuccess: (text, status) => void, onError: (text, status) => void, body = null, traceId = null) {
  return getHttpBody(url, (text, status) => {
    if (onSuccess) onSuccess(JSON.parse(text), status)
  }, onError, JSON.stringify(body), traceId)
}

export function putHttp(url: string, onSuccess: (text, status) => void, onError: (text, status) => void, body = null, traceId = null) {
  return http('PUT', url, body, onSuccess, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, getAccessJwtProvider(), traceId)
}

export function putHttpJson(url: string, onSuccess: (obj, status) => void, onError: (text, status) => void, body = null, traceId = null) {
  return http('PUT', url, body, (text, status) => {
    if (onSuccess) onSuccess(JSON.parse(text), status)
  }, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, getAccessJwtProvider(), traceId)
}

export function postHttp(url: string, onSuccess: (text, status) => void, onError: (text, status) => void, body = null, _traceId = null) {
  let jwtProvider = getAccessJwtProvider()
  return http('POST', url, body, onSuccess, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, jwtProvider, /*traceId +*/ jwtProvider ? " provider non null " : " provider null ")
}

export function postHttpJson(url: string, onSuccess: (obj, status) => void, onError: (text, status) => void, body = null, _traceId = null) {
  let jwtProvider = getAccessJwtProvider()
  return http('POST', url, body, (text, status) => {
    if (onSuccess) onSuccess(JSON.parse(text), status)
  }, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, jwtProvider, /*traceId +*/ jwtProvider ? " provider non null " : " provider null ")
}

export function deleteHttp(url: string, onSuccess: (text, status) => void, onError: (text, status) => void, body = null, traceId = null) {
  return http('DELETE', url, body, onSuccess, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, getAccessJwtProvider(), traceId)
}

export function deleteHttpJson(url: string, onSuccess: (text, status) => void, onError: (text, status) => void, body = null, traceId = null) {
  return http('DELETE', url, body, (text, status) => {
    if (onSuccess) onSuccess(JSON.parse(text), status)
  }, onError ? onError : function (text, status) {
    alert("Error: " + status + " - " + text)
  }, getAccessJwtProvider(), traceId)
}

function isError(xhr: XMLHttpRequest, binaryDownload: boolean) {
  var res = binaryDownload ? xhr.response : xhr.responseText

  if (binaryDownload)
    return xhr.status >= 400

  if (res.startsWith("{") && res.endsWith("}")) {
    try {
      let errObj = JSON.parse(res)

      if (errObj && (errObj.status == "ERROR" || errObj.status == "STOP" || errObj.status == "MISSING"))
        return true
    } catch (e) {

    }
  }

  return false
}

function http(method: string, url: string, body: string, onSuccessHandler: (response, status) => void, onErrorHandler: (response, status) => void, jwtProducer: JwtProducer, traceId: string, binaryDownload: boolean = false) {
  function httpLoadWithXhr(jwtString: string) {
    let xhr = new XMLHttpRequest();

    xhr.open(method, url);

    if (binaryDownload)
      xhr.responseType = 'blob';

    if (jwtString) {
      //console.log("XHR loading " + method + " with JWT " + (traceId || "") + ": " + url);
      xhr.setRequestHeader("Authorization", addBearerTo(jwtString));
    } else
      //console.log("XHR loading " + method + " without JWT" + (traceId || "") + ": " + url);

    if (traceId) {
      xhr.setRequestHeader("X-E-TraceId", traceId);
    }

    try {
      // Track the state changes of the request.
      xhr.onreadystatechange = function () {
        let DONE = 4; // readyState 4 means the request is done.
        let OK = 200; // status 200 is a successful return.

        if (xhr.readyState === DONE) {
          if (xhr.status === OK) {
            if (isError(xhr, binaryDownload)) {
              console.log("utils.http() - error: " + xhr.status + (binaryDownload ? "" : " - " + xhr.responseText) + " - url: " + url)
              if (onErrorHandler) {
                onErrorHandler(binaryDownload ? "Http error: " + xhr.status : xhr.responseText, xhr.status)
              }
            } else {
              //console.log("ok: " + xhr.responseText + " - traceId:" + traceId)

              if (onSuccessHandler) {
                onSuccessHandler(binaryDownload ? xhr.response : xhr.responseText, xhr.status)
              }
            }
          } else {
            console.log("utils.http() - error: " + xhr.status + (binaryDownload ? "" : " - " + xhr.responseText))
            if (onErrorHandler) {
              onErrorHandler(binaryDownload ? "Http error: " + xhr.status : xhr.responseText, xhr.status)
            }
          }
        }
      };

      xhr.onerror = function (_e) {
          if (onErrorHandler)
              onErrorHandler("Network error while loading " + url , -1)
          else
            alert("Network error loading " + url);
      };

      if (method == "GET" && body) {
        xhr.setRequestHeader("X-Request-Body", body);
        xhr.send(null);
      } else {
        if ((method == "PUT" || method == "POST" || method == "DELETE") && (typeof (body) === 'object')) {
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.send(JSON.stringify(body));
        } else
          xhr.send(body);
      }
    } catch (e) {
      alert("Exception " + e)
    }
  }

  if (jwtProducer) {
    jwtProducer.asyncGet("http() - " + url, (jwtString, _reason) => {
      httpLoadWithXhr(jwtString);
      //}, onError)
    }, _reason => {
      httpLoadWithXhr(null)
    })
  } else {
    httpLoadWithXhr(null);
  }
}

export function extractJwtUnsafe(jwt: string) {
  if (!jwt)
    return null

  let parts = jwt.split(".");

  return JSON.parse(atob(parts[1]))
}

// Error handling
export function handleError(error) {
  let errorMessage;

  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  window.alert(errorMessage);
  //return throwError(errorMessage);
}

export function returnJsonPromise(log: boolean) {
  return (response) => {
    let dataPromise = response.json();

    if (log)
      console.log(dataPromise);

    return dataPromise.then((data) => {
        if (data.ok === false) {
          return Promise.reject(data);
        }

        return Promise.resolve(data);
      }
    )
  };
}

export function checkNetwork(networkId: number) {
  if (!networkId || isNaN(networkId) || networkId < 0)
    throw "Invalid network id: " + networkId;

  return networkId;
}

export function fetchAndReturnCors(url: URL, log: boolean, method: string, jwt: string = null, jwtAccessExpired: string = null): Promise<any> {
  let init: RequestInit = method === "GET" ? {method: 'GET', mode: 'cors'} :
    {method: method, mode: 'cors', cache: 'no-cache'};

  if (jwt) {
    //console.log("Fetching " + method + " with JWT: " + url);
    if (jwtAccessExpired)
      init.headers = {
        "Authorization": addBearerTo(jwt),
        "Elite-Jwt-Access": jwtAccessExpired
      };
    else
      init.headers = {"Authorization": addBearerTo(jwt)};
  } //else
    //console.log("Fetching " + method + " without JWT: " + url);

  return fetch('' + url, init)
    .then(returnJsonPromise(log), err => {
      if (log)
        console.log(err);
    });
}

export function fetchAndReturnCorsChunked2(urlString: string, method: string, dataListener: (obj, status) => void, onError: (text, status) => void, jwtProducer: JwtProducer = null) {
  async function httpLoadWithFetch(jwtString: string) {
    let init = prepareFetchInit(method, jwtString);

    fetch(urlString, init)
      .then((response) => {
        if (!response.ok) {
          if (onError)
            onError(response.text(), response.status)
          return
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        return new ReadableStream({
          start(controller) {
            function push() {
              reader.read().then(({ done, value}) => {
                if (done) {
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                let text = decoder.decode(value, { stream: true })
                dataListener(text, response.status)

                push();
              });
            }

            push();
          },
        });
      })
      .then((stream) =>
        // Respond with our stream
        new Response(stream, {headers: {"Content-Type": "text/html"}}).text(),
      )
      .then((result) => {
        // Do things with result
        console.log(result);
      });
  }

  if (jwtProducer) {
    jwtProducer.asyncGet("http() - " + urlString, (jwtString, _reason) => {
      httpLoadWithFetch(jwtString);
    }, _reason => {
      httpLoadWithFetch(null)
    })
  } else {
    httpLoadWithFetch(null);
  }
}


function prepareFetchInit(method: string, jwtString: string) {
  let init: RequestInit = method === "GET" ? {
      method: 'GET',
      mode: 'cors'
    } :
    {
      method: method,
      mode: 'cors',
      cache: 'no-cache'
    };

  if (jwtString) {
    init.headers = {"Authorization": addBearerTo(jwtString)};
  }
  return init;
}

export async function fetchAndReturnCorsChunked(urlString: string, method: string, dataListener: (obj, status) => void, onError: (text, status) => void, jwtProducer: JwtProducer = null): Promise<any> {
  async function httpLoadWithFetch(jwtString: string) {
    let init = prepareFetchInit(method, jwtString);
    let chunkTerminator = "_||_"

    try {
      const response = await fetch('' + new URL(urlString), init);

      if (!response.ok) {
        if (onError)
          onError(response.text(), response.status)
        return
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let completeChunk = '';

      while (true) {
        const {
          value,
          done
        } = await reader.read();
        let chunk = decoder.decode(value, {stream: true})
        if (done) {
          //alert("Chunks done")
          break;
        }

        completeChunk += chunk;

        // Process the chunk only if it ends with a newline or other delimiter
        if (completeChunk.endsWith(chunkTerminator)) {
          let newChunk = completeChunk.substring(0, completeChunk.length - chunkTerminator.length)
          let idx = newChunk.lastIndexOf(chunkTerminator)  // SOmetimes we can get both the versions in the same read
          if (idx >= 0)
            newChunk = newChunk.substring(idx + chunkTerminator.length)

          dataListener(newChunk, response.status)
          completeChunk = ''; // Reset for the next chunk
        }
      }
    } catch(e) {
      if (onError)
        onError(e, 0)
    }
  }

  if (jwtProducer) {
    jwtProducer.asyncGet("http() - " + urlString, (jwtString, _reason) => {
      httpLoadWithFetch(jwtString);
    }, _reason => {
      httpLoadWithFetch(null)
    })
  } else {
    httpLoadWithFetch(null);
  }
}

export function cleanId(id: string, role: number) {
  let prefix = role + "}";

  return id.startsWith(prefix) ? id.substring(prefix.length) : id
}

export class NanoUtil {
  static mouseOnArea: boolean = false
}

export function sendMouseMoveToNanoGallery() {
  let nextImgArrow = document.querySelector(".selImgNext");

  if (nextImgArrow) {
    return nextImgArrow.dispatchEvent(new Event('mousemove'));
  }

  return false;
}

export function cleanUpNanoGallery(galleryTarget) {
  if (galleryTarget) {
    try {
      // @ts-ignore
      $nanogallery2(galleryTarget, 'destroy')
    } catch (e) {

    }
  }
}

export function defaultTargetNanoGallery() {
  return document.getElementById("contentTarget")
}

/*export function itemsSelectedNanoGallery(galleryTarget) {
  // @ts-ignore
  //alert(jQuery.nanogallery2)
  if (galleryTarget) {
    console.log("Methods of nanoGallery target: " + getMethods(galleryTarget))

    try {
      // @ts-ignore
      return $nanogallery2(galleryTarget, 'itemsSelectedGet')
    } catch (e) {
    }
  }
}*/

export function getMethods(obj) {
  let properties = new Set()
  let currentObj = obj
  do {
    Object.getOwnPropertyNames(currentObj).map(item => properties.add(item))
  } while ((currentObj = Object.getPrototypeOf(currentObj)))

  // @ts-ignore
  return [...properties.keys()].filter(item => typeof obj[item] === 'function')
}

export function getAttributes(obj) {
  return obj ? Object.entries(obj) : []
  /*for (const [key, value] of Object.entries(obj)) {
    alert(key + ": " + JSON.stringify(value))
  }*/
}

export function copyAttributes(source, target) {
  for (const [key, value] of Object.entries(source)) {
    target[key] = value
  }

  return target
}


export function mergeJson(obj1: any, obj2: any) {
  const result = {};

  Object.keys(obj1)
    .forEach(key => result[key] = obj1[key]);

  Object.keys(obj2)
    .forEach(key => result[key] = obj2[key]);

  return result;
}

// Not used
// From https://spin.atomicobject.com/2018/09/10/javascript-concurrency/
export const notConcurrent = <T>(proc: () => PromiseLike<T>) => {
  let inFlight: Promise<T> | false = false;

  return () => {
    if (!inFlight) {
      inFlight = (async () => {
        try {
          return await proc();
        } finally {
          inFlight = false;
        }
      })();
    }
    return inFlight;
  };
};

export function addBearerTo(accessJwtToken: string): string {
  let idx = accessJwtToken.lastIndexOf("Bearer ");

  if (idx < 0)
    return "Bearer " + accessJwtToken

  return accessJwtToken.substring(idx)
}

export function removeBearerFrom(jwtToken: string): string {
  let idx = jwtToken.lastIndexOf("Bearer ");

  if (idx < 0)
    return jwtToken

  return jwtToken.substring(idx + 7)
}

export function cleanContent(cleanContentTarget = true, cleanExternalRender = true, hideButtons = true) {
  let contentTarget = document.getElementById("contentTarget")

  if (cleanContentTarget && contentTarget) {
    contentTarget.style.display = 'none';
  }

  if (cleanExternalRender) {
    let elExternal: any = document.getElementById("externalRender");

    (elExternal as ExternalRender).externalContent = "";
  }

  if (hideButtons) {
    hideCloseButton();
    hideDeleteButton();
    hideAddButton();
    hideTitleContent();
  }

  return contentTarget
}

function hideElement(id: string) {
  let element = document.getElementById(id)

  if (element) {
    element = recreateNode(element);
    element.style.display = 'none';
    element.addEventListener("click", () => {
    })
  }

  return element
}

// Clone a node, removing all the listeners
export function recreateNode(el, recreateChildren = false) {
  let newButton

  if (recreateChildren) {
    newButton = el.cloneNode(true)
    el.parentNode.replaceChild(newButton, el);
  } else {
    newButton = el.cloneNode(false);

    while (el.hasChildNodes()) newButton.appendChild(el.firstChild);
    el.parentNode.replaceChild(newButton, el);
  }

  return newButton
}

export function showElement(id: string, onCLick = null): HTMLElement {
  let element = document.getElementById(id)

  if (element) {
    element = recreateNode(element);
    element.style.display = 'inline';

    if (onCLick)
      element.addEventListener("click", onCLick)
  }

  return element
}

export function hideCloseButton() {
  hideElement("closeContent")
}

export function showCloseButton(onClick) {
  showElement("closeContent", onClick)
}

export function hideDeleteButton() {
  hideElement("deleteContent")
}

export function showDeleteButton(onClick) {
  showElement("deleteContent", onClick)
}

export function hideAddButton() {
  hideElement("addContent")
}

export function showAddButton(onClick) {
  showElement("addContent", onClick)
}

export function hideTitleContent() {
  hideElement("titleContent")
}

export function showTitleContent(content) {
  let elExternal: any = showElement("titleContent");

  (elExternal as ExternalRender).externalContent = content;
}

export function stringifyCircular(item) {
  let cache = []

  return JSON.stringify(item, (_key, value) => {
    if (typeof value === 'object' && value !== null) {
      // Duplicate reference found, discard key
      if (cache.includes(value)) return;

      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
}

export function getThresholdColor(value: number) {
  if (value > 0.90)
    return "red"

  if (value > 0.75)
    return "orange"

  return "green"
}

export function firstCharUppercase(str: string) {
  return str.substring(0, 1).toUpperCase() + str.substring(1)
}

/** Resizes an iframe, to be able to show all the content; it's not super precise, but it should work */
export function iframeResize(ifr, securityMargin = 15) {
  let h = ifr.contentWindow.document.body.scrollHeight
  ifr.style.height =
    (h + securityMargin) + 'px';

  //alert(ifr.contentWindow.document.body.clientHeight + " vs " + h)
}

// Base64 is not URL friendly, and Spring boot complains about "/"; this functions fixes it client side
// Server side you need to use CryptoHash.decodeUriForBase64() or CryptoHash.decodeUriBase64AsString()
export function encodeBase64ForUrl(base64: string) {
  return base64.replace(/\+/g, ".").replace(/\//g, "_").replace(/=/g, "-");
}

// Base64 is not URL friendly, and Spring boot complains about "/"; this functions fixes it client side
// Server side you need to use CryptoHash.encodeUriBase64AsString()
export function decodeBase64FromUrl(base64: string) {
  return base64.replace(/\./g, "+").replace(/_/g, "\/").replace(/-/g, "=");
}

export function getUrlParams(): URLSearchParams {
  return new URLSearchParams(window.location.search)
}

export function getUrlParam(paramName: string) {
  return getUrlParams().get(paramName)
}

export function setUrlParam(paramName: string, paramValue, reload: boolean = false /* if false, the URL will be changed without reload*/) {
  const urlWithoutParams = new URL(window.location.href).pathname;
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set(paramName, paramValue);

  const newQueryString = urlParams.toString();
  const newUrl = `${urlWithoutParams}?${newQueryString}`

  if (reload)
    window.location.href = newUrl
  else
    window.history.replaceState(null, '', newUrl);
}

export function deleteUrlParam(paramName: string, reload: boolean = false /* if false, the URL will be changed without reload*/) {
  const urlWithoutParams = new URL(window.location.href).pathname;
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.delete(paramName);

  const newQueryString = urlParams.toString();
  const newUrl = `${urlWithoutParams}?${newQueryString}`

  if (reload)
    window.location.href = newUrl
  else
    window.history.replaceState(null, '', newUrl);
}

export function uploadAllFiles(files: FileList, urlUpload: string, onPreview: (File) => void, onProgress: (filesDone, filesSuccess, filesToDo, result) => void, additionalParams: Map<string, any> = null) {
  let filesSuccess = 0

  let filesDone = 0
  let filesToDo = files.length

  for (let i = 0; i < files.length; i++) {
    let file = files[i];

    if (onPreview)
      onPreview(file);
    // Can we make it faster, sending all the files, while tracking the progress?
    uploadFile(file, urlUpload, r => {
      let res = JSON.parse(r)

      if (res.ok)
        filesSuccess++
      else
        alert("Error: " + res.userMessage)
      filesDone++

      if (onProgress)
        onProgress(filesDone, filesSuccess, filesToDo, res)
    }, r => {
      filesDone++
      if (onProgress)
        onProgress(filesDone, filesSuccess, filesToDo, null)
      alert("Error: " + r)
    }, additionalParams);
  }
}

export function uploadFile(fileOrBlob, urlUpload: string, onSuccess, onError, additionalParams: Map<string, any> = null) {
  let formData = new FormData();

  formData.append('file', fileOrBlob);
  formData.append('params', AuthStore.getInstance().getLicenseParams());

  let n = 0
  if (additionalParams) {
    for (let [key, value] of additionalParams.entries()) {
      console.assert(key != "file")
      console.assert(key != "params")
      n++
      formData.append(key, isString(value) ? value : JSON.stringify(value));
    }

    if (n == 0) {
      for (let key in additionalParams) {
        let value = additionalParams[key]
        console.assert(key != "file")
        console.assert(key != "params")
        formData.append(key, isString(value) ? value : JSON.stringify(value));
      }
    }
  }

  getAccessJwtTokenAsync(bearer => {
    fetch(urlUpload, {
      method: 'POST',
      body: formData,
      headers: {
        "Authorization": addBearerTo(bearer)
      }
    })
      .then((r) => {
        if (r.status == 200) {
          if (onSuccess)
            r.text().then(onSuccess)
        } else {
          if (onError)
            r.text().then(onError)
          else
            alert("Error " + r.status + ": " + r.statusText)
        }
        /* Done. Inform the user */
      })
      .catch((e) => {
        alert(e)  /* Error. Inform the user */
      })
  })
}

export function resizeImageToCanvas(image: HTMLImageElement, maxSize: number): HTMLCanvasElement {
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }
  }

  canvas.width = width;
  canvas.height = height;
  canvas.getContext('2d').drawImage(image, 0, 0, width, height);

  return canvas
}

export function dataURItoBlob(dataURI: string): Blob {
  const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
    atob(dataURI.split(',')[1]) :
    unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);

  for (var i = 0; i < max; i++)
    ia[i] = bytes.charCodeAt(i);

  return new Blob([ia], {type: mime});
}


export function resizeFileToBlob(file: File, maxSize: number, qualityPercentage: number = 0.8): Promise<Blob> {
  const reader = new FileReader();
  const image = new Image();

  const resizeAfterLoad: () => Blob = () => {
    let canvas = resizeImageToCanvas(image, maxSize)
    let dataUrl = canvas.toDataURL('image/jpeg', qualityPercentage);

    return dataURItoBlob(dataUrl);
  };

  return new Promise<Blob>((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resizeAfterLoad());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  })
}

export function addCssFile(cssFile: String) {
  if (cssFile) {
    let lnk = document.createElement('link');

    lnk.href = EliteConfig.cssUrl + "/" + cssFile;
    lnk.rel = 'stylesheet';
    lnk.type = 'text/css';

    (document.head || document.documentElement).appendChild(lnk);
  }
}

export function addJavascriptFile(jsUrl: string, onload: () => void, async = true, defer = false) {
  if (jsUrl) {
    let script = document.createElement('script');

    if (onload)
      script.onload = onload

    script.async = async
    if (defer)
      script.defer = true

    script.src = jsUrl;

    document.head.appendChild(script);
  }
}

export function createMeta(name: string, content: string) {
  let meta: HTMLMetaElement = document.createElement("meta")

  meta.name = name
  meta.content = content
  document.head.appendChild(meta)
}

export function defineGlobalFunction(fnName, fn) {
  window[fnName] = fn
}

export function addCssInline(css: string) {
  if (css) {
    let style = document.createElement('style');

    style.type = 'text/css';
    //@ts-ignore
    if (style.styleSheet) {
      // This is required for IE8 and below.
      //@ts-ignore
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    (document.head || document.documentElement).appendChild(style);
  }


}

export function activateTheme(roleId: number, callback: () => void) {
  if (location.href.startsWith("http://foqoos.com") || location.href.startsWith("https://foqoos.com"))
    roleId = -1

  let override = getUrlParam("withCss")

  if (override)
    roleId = parseInt(override)

  UsersProfileStore.getInstance().loadTheme(roleId, (res: any) => {
    let css = res.defaultCss

    if (res.colors) {
      css += jsonToCss(JSON.parse(res.colors))
    }

    addCssInline(css)
    callback()
  })
}

export function extractJson(json: any, level1: String = null, level2: String = null, level3: String = null, level4: String = null, level5: String = null) {
  //@ts-ignore
  //alert("Full: " + level1 + " - " + level2 + " - " + level3 + " - " + JSON.stringify(json))

  let jsonL1 = null
  if (level1 && level1.startsWith("+")) {
    level1 = level1.substring(1)
    let j = JSON.parse(json)
    //alert("Level 1 - 1a: " + j)
    //@ts-ignore
    jsonL1 = j[level1]
    //alert("Level 1 - 1b: " + JSON.stringify(jsonL1))
  } else {
    //@ts-ignore
    jsonL1 = json[level1]
    //alert("Level 1 - 2: " + jsonL1)
  }

  //@ts-ignore
  if (jsonL1) {
    //@ts-ignore
    if (level2) {
      //@ts-ignore
      return extractJson(jsonL1, level2, level3, level4, level5)
    } else
      //@ts-ignore
      return jsonL1
  }

  return json
}

function extractCss(json: any, level1: String, level2: String, prefix: String) {
  //@ts-ignore
  if (json && json[level1] && json[level1][level2]) {
    //@ts-ignore
    return prefix + json[level1][level2] + ";\n"
  }

  return ""
}

function jsonToCss(colors: any) {
  let css = ""

  css += extractCss(colors, "menu", "backgroundColor", "--menu-bk:")
  css += extractCss(colors, "menu", "fontColor", "--menu-color:")
  css += extractCss(colors, "content", "backgroundColor", "--content-bk:")
  css += extractCss(colors, "content", "fontColor", "--content-color:")
  css += extractCss(colors, "buttons", "radius", "--buttons-radius:")

  return ":root {\n" + css + "\n}"
}

export function toBoolean(str: String) {
  return "true" == str.toLowerCase()
}

export function selectOptionByContent(selectControl: HTMLSelectElement, expectedValue: String, mapper: (HTMLOptionElement) => String = null) {
  if (selectControl) {
    for (let i = 0; i < selectControl.options.length; i++) {
      let value = mapper ? mapper(selectControl.options[i]) : selectControl.options[i].value
      if (value == expectedValue) {
        selectControl.selectedIndex = i
        selectControl.options[i].selected = true;

        return i
      }
    }

    return -1;
  }
  return 0;
}

export function urlMerge(base: string, other) {
  if (other)
    return base + (base.endsWith("/") ? "" : "/") + other

  if (base.endsWith("/"))
    return base.substring(0, base.length - 1)

  return base
}

export function result(data): any {
  return JSON.parse(data)?.result
}

/*export function getMenuCss(backgroundColor: string) {
  return ":root {\n" +
    "--menu-bk: " + backgroundColor + "\n" +
    "}"
}*/

export class UserLoginInfo {
  public givenName: string
  public familyName: string
  public fullName: string
  public email: string
  public jwtToken: string
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export enum FuzzyBoolean {
  TRUE, FALSE, MAYBE
}

export namespace FuzzyBoolean {
  export function fromPositiveNumber(value: number): FuzzyBoolean {
    if (value < 0 || value == undefined || value == null)
      return FuzzyBoolean.MAYBE

    if (value > 0)
      return FuzzyBoolean.TRUE

    return FuzzyBoolean.FALSE
  }
}

export class CarouselCard {
  public title?: string
  public text?: string
  public html?: string
  public imageUrl?: string
  public enabled?: FuzzyBoolean
}

export interface CardsWithPos {
  cards: CarouselCard[] | string
  currentCard: number
  showButtonsAbove: boolean

  renderButtons(): any
}

export class CardsWithPosUtil {
  public static chooseCurrentCard(obj: CardsWithPos): CarouselCard[] {
    let cards = CardsWithPosUtil.getCards(obj)

    if (obj.cards && obj.currentCard < 0) {
      for (let i = 0; i < cards.length; i++) {
        if (cards[i].enabled === FuzzyBoolean.MAYBE) {
          obj.currentCard = -1

          return cards
        }

        if (cards[i].enabled !== FuzzyBoolean.FALSE) {
          obj.currentCard = i

          return cards
        }
      }

      obj.currentCard = 0;
    }

    return cards
  }

  public static getCards(obj: CardsWithPos): CarouselCard[] {
    return typeof obj.cards == 'string' ? JSON.parse(obj.cards) : obj.cards
  }

  public static renderCustom(obj: CardsWithPos, renderFn: (card: CarouselCard, pos: number) => any): any {
    if (!obj.cards)
      return ""

    let cards = CardsWithPosUtil.getCards(obj)
    let ar = []

    for (let i = 0; i < cards.length; i++) {
      ar.push(renderFn(cards[i], i))
    }

    return ar
  }
}

export function px(value) {
  if (!value)
    return null

  if (typeof value == 'string' && value.endsWith("%"))
    return value

  return value + "px"
}


export class UriBuilder {
  private uri: string

  constructor(url: string) {
    this.uri = url.includes('?') ? url : url + "?";
  }

  with(paramName: string, value: string): UriBuilder {
    if (paramName) {
      this.uri += this.uri.endsWith("?") ? "" : "&"

      this.uri += paramName + "="
      this.uri += encodeURIComponent(value)
    }

    return this
  }

  build(): string {
    return this.uri
  }
}

export function addUrlParam(url: string, paramName: string, paramValue: string): string {
  return new UriBuilder(url).with(paramName, paramValue).build()
}

export function formFieldByName(form: HTMLFormElement, fieldName: string) {
  for (let f of Array.from(form.elements)) {
    if (f.getAttribute("name") == fieldName)
      return f
  }

  return null
}

export function formFieldById(form: HTMLFormElement, fieldId: string) {
  for (let f of Array.from(form.elements)) {
    if (f.id == fieldId)
      return f
  }

  return null
}

export class Debug {
  public static active: boolean = false

  public static trace(str: string): void {
    AuthStore.log(str)
    if (Debug.active)
      console.log(str)
  }
}

export function deepUpdateMap(map: Map<string, any>, key: string, value: any): Map<string, any> {
  let newMap = new Map<string, any>();

  for (let i in map)
    newMap[i] = map[i];

  newMap[key] = value

  return newMap
}

export function isUserAdmin(role): boolean {
  // FIXME: A creator should work as well
  return "ADMIN" === role?.group?.toUpperCase() || "NETWORK-ADMIN" === role?.group?.toUpperCase()
}

export enum SortingState {
  UNSORTED, ASCENDING, DESCENDING
}

export class SortingInfo {
  colName: string
  state: SortingState

  constructor(colName: string, state: SortingState) {
    this.colName = colName;
    this.state = state;
  }
}

export class ElementsGroup<Type, TypeInfo> {
  readonly items: Type[] = []
  readonly onNotify: ((info: TypeInfo) => void)[] = []
  readonly name: string

  constructor(name: string = null) {
    this.name = name;
  }

  register(item: Type) {
    this.items.push(item)
  }

  addListener(onNotify: (info: TypeInfo) => void) {
    this.onNotify.push(onNotify)
  }

  notify(info: TypeInfo) {
    this.onNotify.forEach(notify => notify(info))
  }
}

export class Refresher {
  private listeners: ((any) => void)[] = []


  add(listener: (any) => void) {
    this.listeners.push(listener)
  }

  refresh(obj: any = null) {
    for (let l of this.listeners)
      l(obj)
  }
}

export interface DataStorage {
  setItem(key: string, value: string): void;

  clear(): void;

  /** Returns the current value associated with the given key, or null if the given key does not exist. */
  getItem(key: string): string | null;

  removeItem(key: string): void;
}

export class DataStorages {
  private static ALLOW_DATA_PERSISTENCE: string = "allowDataPersistence"
  private static persistData: boolean = false
  private static map: DataStorage = new class implements DataStorage {
    backingMap: Map<string, string> = new Map()

    clear(): void {
      this.backingMap.clear()
    }

    getItem(key: string): string {
      return this.backingMap.get(key)
    }

    removeItem(key: string): void {
      this.backingMap.delete(key)
    }

    setItem(key: string, value: string): void {
      this.backingMap.set(key, value)
    }
  }

  // @ts-ignore
  private static _initialize = (() => {
    let allowDataPersistence = DataStorages.persistent().getItem(DataStorages.ALLOW_DATA_PERSISTENCE)

    if (allowDataPersistence === "true")
      DataStorages.persistData = true
  })();

  public static persistent(): DataStorage {
    return localStorage  // If it looks like a duck...
  }

  public static session(): DataStorage {
    return sessionStorage  // If it looks like a duck...
  }

  public static inMemory(): DataStorage {
    return DataStorages.map
  }

  private static exec(consumer: (ds: DataStorage) => void) {
    consumer(DataStorages.inMemory())

    if (DataStorages.persistData)
      consumer(DataStorages.persistent())
  }

  public static auto(): DataStorage {
    return new class implements DataStorage {
      clear(): void {
        DataStorages.exec(ds => ds.clear())
      }

      getItem(key: string): string | null {
        if (DataStorages.persistData) {
          let value = DataStorages.persistent().getItem(key);

          if (value)
            return value
        }

        return DataStorages.inMemory().getItem(key)
      }

      removeItem(key: string): void {
        DataStorages.exec(ds => ds.removeItem(key))
      }

      setItem(key: string, value: string): void {
        DataStorages.exec(ds => ds.setItem(key, value))
      }
    }
  }

  public static allowPersistence(allowDataPersistence: boolean, allowConsentPersistence: boolean) {
    DataStorages.persistData = allowDataPersistence

    if (!allowDataPersistence)
      DataStorages.persistent().clear()

    if (allowDataPersistence || allowConsentPersistence) {
      DataStorages.persistent().setItem(DataStorages.ALLOW_DATA_PERSISTENCE, "" + allowDataPersistence)
    }
  }

  public static userNotified(): boolean {
    let allowDataPersistence = DataStorages.persistent().getItem(DataStorages.ALLOW_DATA_PERSISTENCE)

    return allowDataPersistence != null
  }

  public static removeItemsWithPrefix(prefix: string) {
    DataStorages.removeItemsWithPrefixFromStorage(sessionStorage, prefix)
    DataStorages.removeItemsWithPrefixFromStorage(localStorage, prefix)
  }

  private static removeItemsWithPrefixFromStorage(storage: Storage, prefix: string) {
    let len = storage.length

    for(let i=0; i<len; i++) {
      let key = storage.key(i)

      if (key?.startsWith(prefix))
        storage.removeItem(key)
    }
  }
}


export function getWeek(date: Date) {
  if (date == null)
    return ""

  let onejan = new Date(date.getFullYear(), 0, 1);
  let dayOfYear = ((date.valueOf() - onejan.valueOf()) / 86400000);

  return Math.ceil(dayOfYear / 7)
}

export function isValidEmail(emailAddress)
{
  if (!emailAddress)
      return false
  //let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let mailFormat = "^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$" // from the RFC 5322

  return emailAddress.match(mailFormat)
}

export function readAttribute(obj: unknown, attributeName: string): unknown {
    if (typeof obj === 'object' && obj !== null) {
        return (obj as Record<string, unknown>)[attributeName];
    }

    return undefined;
}

export function inputValue(event) {
    return (event.target  as HTMLInputElement).value
}

export function userData() {
  return {
    userAgent: navigator.userAgent,
    language: navigator.language || (navigator as any).userLanguage,
    referrer: document.referrer
  }
}

export function isMobile() {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        return true
    }

    if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || ((navigator as any).msMaxTouchPoints && (navigator as any).msMaxTouchPoints > 0)) {
        return true
    }
}

export function isScreenVertical() {
    return window.innerHeight > window.innerWidth
}

export function isScreenHorizontal() {
    return !isScreenVertical()
}

export function cssClass(userClass = null) {
  let before = userClass ? userClass + " " : ""
  let after = isMobile() ? " mobile" : ""

  if (isScreenVertical())
    return before +"vertical" + after

  return before + "horizontal" + after
}

// Can be used to store some temporarily values
export function setTemporarilyPersistedPageValue(pageName: string, key: string, value: string, timeout: number) {
  let threshold: number = timeout ? Date.now() + timeout : 0
  sessionStorage.setItem(pageName + "/" + key, value + "|" + threshold)
}

export function getTemporarilyPersistedPageValue(pageName: string, key: string, remove: boolean) {
  let value: string = sessionStorage.getItem(pageName + "/" + key)

  if (!value)
    return null

  if (remove)
    sessionStorage.removeItem(pageName + "/" + key)

  let idx = value.lastIndexOf("|")

  if (idx > 0) {
    let threshold: number = parseInt(value.substring(idx + 1))

    if (Date.now() > threshold)
      return null;

    return value.substring(0, idx)
  }

  return value;
}

export const enterKeyDownEvent = new KeyboardEvent('keydown', {
  bubbles: true,
  cancelable: true,
  key: 'Enter',
  code: 'Enter',
});

export const escapeKeyDownEvent = new KeyboardEvent('keydown', {
  bubbles: true,
  cancelable: true,
  key: 'Escape',
  code: 'Escape',
});

export function isPunctuationOrSpace(str: string) {
  return str == " " || str == ";" || str == ":" || str == "." || str == ","
}

export function userJustSignedUp(removeFromLink = false) : boolean {
  let createOnLink = window.location.href.includes("userCreated=true")

  if (createOnLink)
    DataStorages.session().setItem("userJustSignedUp", "true")

  if (removeFromLink && createOnLink) {
    window.location.href = window.location.href.replace("userCreated=true", "")
  }

  if (createOnLink)
    return true

  return DataStorages.session().getItem("userJustSignedUp") == "true";
}

export function limitString(str: string, maxLen: number, maxCharactersToDrop = 20): string {
  if (str.length <= maxLen)
    return str

  let len = maxLen

  while(!isPunctuationOrSpace(str.charAt(len)) && len > maxCharactersToDrop - 20)
    len--

  return str.substring(0, len) + "..."
}

export interface GestureHandlers {
  onLeft: () => void
  onRight: () => void
  onTop: () => void
  onBottom: () => void
  onPinch: (zoom) => void
}

class MouseGestureState {
  static lastAction = 0
}

export function mouseGesture(element, handlers: GestureHandlers, rateLimitMs=100) {
  new AlloyFinger(element, {
    swipe: (evt) => {
      let now = Date.now()

      if (now < MouseGestureState.lastAction + rateLimitMs)
        return

      //console.log(MouseGestureState.lastAction + " vs " + now)

      MouseGestureState.lastAction = now

      if (evt.direction == 'Left' && handlers.onLeft)
        handlers.onLeft()
      else if (evt.direction == 'Right' && handlers.onRight)
        handlers.onRight()
      else if (evt.direction == 'Up' && handlers.onTop)
        handlers.onTop()
      else if (evt.direction == 'Down' && handlers.onBottom)
        handlers.onBottom()
    },
    pinch: (evt) => {
      let now = Date.now()

      if (now < MouseGestureState.lastAction + rateLimitMs)
        return

      MouseGestureState.lastAction = now

      if (handlers.onPinch)
        handlers.onPinch(evt.zoom)
    }
  });
}

export function downloadUrl(imageUrl: string, newFileName: string) {
  let init: any = {
    method: 'GET',
    mode: 'cors'
  }

  fetch(imageUrl, init)
    .then(response => response.blob()) // Convert the response to a Blob
    .then(blob => {
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element for the download
      const link = document.createElement("a");
      link.href = url;
      link.download = newFileName; // Set the suggested filename with the new extension

      // Append the link, click it, and remove it from the DOM
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up by revoking the Blob URL
      URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error downloading:', error));
}

export function isFullScreen() {
  return document.fullscreenElement != null
}

export function enterFullScreen(onError: (err) => void = null) {
  if (!document.fullscreenElement) {
    // If the document is not in fullscreen mode, request fullscreen mode
    document.documentElement.requestFullscreen().catch(err => {
      if (onError)
        onError(err)
    });
  }
}

export function exitFullScreen() {
  if (document.fullscreenElement && document.exitFullscreen) {
    document.exitFullscreen()
  }
}

export function toggleFullScreen(onError: (err) => void = null) {
  if (!document.fullscreenElement)
    enterFullScreen(onError)
  else
    exitFullScreen()
}

export function isDigit(char: string) {
  return char >='0' && char <= '9'
}

export function fixNumberSort(title: string): string {
  let numDigits = 0
  let number = ''

  if (!isDigit(title.charAt(title.length-1)))
    return title


  while (numDigits<=3 && isDigit(title.charAt(title.length-1-numDigits))) {
    number = title.charAt(title.length-1-numDigits) + number
    numDigits++
  }

  if (numDigits > 3)
    return title  // Probably already taken care of

  let txtNoDigits = title.substring(0, title.length-numDigits)

  while(numDigits <=3) {
    number = '0' + number
    numDigits++
  }

  return txtNoDigits + number
}

export function isLocalHost(): boolean {
  return (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
}
export function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // This is important for cross-origin images
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error(`Failed to load image from URL: ${url}`));
    img.src = url;
  });
}

export function rotateImage90Degrees(image) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Swap canvas width and height for 90 degree rotation
    canvas.width = image.naturalHeight;
    canvas.height = image.naturalWidth;

    // Move the context's origin to the center of the canvas
    ctx.translate(canvas.width / 2, canvas.height / 2);

    // Rotate the canvas context by 90 degrees
    ctx.rotate(90 * Math.PI / 180);

    // Draw the image, offsetting by half its width and height to center it
    ctx.drawImage(image, -image.naturalWidth / 2, -image.naturalHeight / 2);

    // Convert canvas to blob
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Canvas to Blob conversion failed.'));
      }
    }, 'image/png');
  });
}

export function hasNamedSlot(element: HTMLElement, slotName: string): boolean {
  const slot = element.querySelector(`slot[name="${slotName}"]`) as any;

  return slot && slot.assignedNodes && slot.assignedNodes().length > 0;
}

export function generateRandomRedirectUrl() {
  let url = window.location.href
  let idx = url.indexOf('?')

  if (idx && idx > 0)
    url = url.substring(0, idx)

  return url + "?" + Math.random()
}

export function stringifyMap(obj) : string {
  if (obj == null)
    return null

  if (obj.entries) {
    return JSON.stringify(Array.from(obj.entries()))
  }

  return JSON.stringify(getAttributes(obj))
}

export function combineDateAndTime(datePart: Date | string, timePart: Date | string | any): Date {
  if (!datePart)
    return dt

  var dt = new Date(datePart)

  if (!timePart)
    return dt

  if (typeof timePart === "string") {
    if (timePart.length >= 5 && timePart[2] === ":") {
      dt.setHours(parseInt(timePart.substring(0, 2), 10), parseInt(timePart.substring(3, 5), 10), 0, 0)
    }
  } else if (timePart.hour && timePart.minute)
    dt.setHours(timePart.hour, timePart.minute, 0, 0)
  else if (typeof timePart.getHours === 'function')
    dt.setHours(timePart.getHours(), timePart.getMinutes(), timePart.getSeconds(), timePart.getMilliseconds())

  return dt
}

export function extractDate(dateTime: string | Date): string {
  if (!dateTime)
    return null

  if (dateTime instanceof Date) {
    //return dateTime.toISOString().split('T')[0]
    return new Intl.DateTimeFormat('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(dateTime);
  }

  let idx = dateTime.indexOf('T')

  if (idx<0)
    return dateTime

  return dateTime.substring(0, idx)
}

export function extractDateTime(dateTime: string) {
  if (!dateTime)
    return null

  let idx = dateTime.indexOf(':')

  if (idx<0)
    return dateTime

  idx = dateTime.indexOf(':', idx + 1)

  if (idx<0)
    return dateTime

  return dateTime.substring(0, idx).replace('T', ' ')
}

export function extractTime(dateTime: string | Date | any): string {
  if (!dateTime)
    return null

  if (dateTime instanceof Date) {
    //return dateTime.toTimeString().substring(0, 5)
    return new Intl.DateTimeFormat('en-CA', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(dateTime);
  }

  if (dateTime.timeString)
    return dateTime.timeString

  dateTime = extractDateTime(dateTime)
  if (!dateTime)
    return null

  let idxTimeStart = dateTime.indexOf(' ')

  if (idxTimeStart<0)
    return dateTime

  return dateTime.substring(idxTimeStart+1)
}

export function extractDateTimeRange(date1: string, date2: string) {
  let dateOnly1 = extractDate(date1)
  let dateOnly2 = extractDate(date2)

  if (!dateOnly1 || !dateOnly2)
    return null

  if (dateOnly1 !== dateOnly2)
    return dateOnly1 + " - " + dateOnly2

  let time1 = extractTime(date1)
  let time2 = extractTime(date2)

  if (!time1 || !time2)
    return dateOnly1 + " - " + dateOnly2


  return dateOnly1 + " " + time1 + "-" + time2
}

export function extractTimeRange(date1: string, date2: string, onlySameDay: boolean = false) {
  let dateOnly1 = extractDate(date1)
  let dateOnly2 = extractDate(date2)

  if (!dateOnly1 || !dateOnly2)
    return null

  if (dateOnly1 !== dateOnly2 && onlySameDay)
    return extractDateTime(date1) + " - " + extractDateTime(date2)

  let time1 = extractTime(date1)
  let time2 = extractTime(date2)

  if (!time1 || !time2)
    return dateOnly1 + " - " + dateOnly2


  return time1 + "-" + time2
}

export function groupBy<T extends Record<string, any>>(array: T[], key: keyof T): Record<string, T[]> {
  return array.reduce((result, currentValue) => {
    const groupKey = String(currentValue[key]);
    (result[groupKey] = result[groupKey] || []).push(currentValue);
    return result;
  }, {} as Record<string, T[]>);
}

export function joinStrings(separator: string, objects: string[], skipNUll = false /*warning, for keys this might not be good*/) {
  if (objects == null)
    return ""

  if (skipNUll)
    objects = objects.filter(it => it != null)
  else
    objects = objects.map(it => it != null ? it : "")

  return objects.join(separator)
}

export function loadStandardCss() {
  loadCssStyle("global.css")
  if (isMobile())
    loadCssStyle("mobile.css")
}

export function loadCssStyle(name, path = "global/") {
  const cssId = name.replace(".css", "-css");  // ID to prevent multiple additions

  if (!document.getElementById(cssId)) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = path + name;  // Adjust the path as needed
    link.id = cssId;  // Assign the ID
    document.head.appendChild(link);
  }
}

export function retryOperation(
  operation: (callback: (succeeded: boolean) => void) => void,
  retryTimeout: number,
  maxTimeout: number,
  onSuccess: () => void,
  onFailure: () => void,
): void {
  let maxTime = Date.now() + maxTimeout;

  function attempt(): void {
    // Check if END_TIMEOUT has been passed
    if (Date.now() > maxTime) {
      console.error("Operation timed out");
      if (onFailure) onFailure();
      return;
    }

    // Perform the operation
    operation((succeeded: boolean) => {
      if (succeeded) {
        if (onSuccess) onSuccess();
      } else {
        // Retry after retryTimeout if not successful
        window.setTimeout(attempt, retryTimeout);
      }
    });
  }

  // Start the first attempt
  attempt();
}

export class ErrorHolder {
  private additionalErrors: string[] = []
  constructor(public error: string = null) {}

  // To use in controls that needs the user to enter a value
  // returns true if this value requires a value and did not get it
  requiresValue(controlValue: string | number, errorMessage: string, extraErrorMessage: string = null): boolean {
    if (this.error) {
      if (!controlValue || (typeof controlValue == 'string' && controlValue.length == 0)) {
        this.additionalErrors.push(extraErrorMessage ? extraErrorMessage + errorMessage : errorMessage);

        return true
      }

      return false;
    }

    if (controlValue && (typeof controlValue == 'string' && controlValue.length>0)) {
      return false;
    }

    if (controlValue && (typeof controlValue == 'number')) {
      return false;
    }

    this.error = extraErrorMessage ? extraErrorMessage + errorMessage : errorMessage

    return true;
  }

  requiresInteger(controlValue: string | number, errorMessage: string, extraErrorMessage: string = null): boolean {
    if (controlValue && typeof controlValue == 'string' && controlValue.trim().length > 0) {
      if (!isValidInteger(controlValue)) {
        this.error = "Invalid number: " + controlValue
        return true
      }

      return false
    }

    return this.requiresValue(controlValue, errorMessage, extraErrorMessage)
  }

  requiresFloat(controlValue: string | number, errorMessage: string, extraErrorMessage: string = null): boolean {
    if (controlValue && typeof controlValue == 'string' && controlValue.trim().length > 0) {
      if (!isValidFloat(controlValue)) {
        this.error = "Invalid number: " + controlValue
        return true
      }

      return false
    }

    return this.requiresValue(controlValue, errorMessage, extraErrorMessage)
  }

  correctIf(controlValue: boolean, errorMessage: string, extraErrorMessage: string = null): boolean {
    return this.requiresValue(controlValue === true ? "TRUE" : null, errorMessage, extraErrorMessage)
  }

  wrongIf(controlValue: boolean, errorMessage: string, extraErrorMessage: string = null): boolean {
    return this.requiresValue(controlValue === true ? null : "TRUE", errorMessage, extraErrorMessage)
  }

  getAdditionalErrorsNumber(): number {
    return this.additionalErrors.length
  }

  getAdditionalErrors() {
    return this.additionalErrors
  }

  hasNoErrors(): boolean {
    return this.error == null
  }

  hasErrors(): boolean {
    return this.error != null
  }
}

export function isValidInteger(value: string): boolean {
  const trimmedValue = value.trim();
  const parsed = parseInt(trimmedValue, 10);

  return !isNaN(parsed) && parsed.toString() === trimmedValue;
}

export function isValidFloat(value: string): boolean {
  const trimmedValue = value.trim();
  const parsed = parseFloat(trimmedValue);

  return !isNaN(parsed);
}
